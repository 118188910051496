<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="section-form-content">
          <div class="block-content lfont">
            <div v-if="listRecruitment.length > 0">
              <v-row>
                <v-col cols="3" md="3" lg="3" v-for="(item, index) in listRecruitment">
                  <v-card @click="detail(item.id)">
                    <v-card-title><span class="lfont text-primary">{{ item.position }}</span></v-card-title>
                    <v-card-text>
                      <p>ຜູ້ຂໍ: {{ item.requester.name }} {{ item.requester.surname }}</p>
                      <p>
                      <h1 class="text-center">ຕ້ອງການ <span class="text-primary">{{ item.need_qty }}</span> ຕຳແໜ່ງ</h1>
                      </p>
                      <p>
                      <h1 class="text-center">ເລືອກ <span class="text-primary">{{ item.qty_requester_selected
                      }}</span> ຄົນ</h1>
                      </p><br>
                      <p class="text-primary">ວັນທີ່ຂໍ: {{ item.created }}</p>
                      <p class="text-danger">ວັນທີ່ຕ້ອງການ: {{ item.need_date }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
                @paginate="fetchRecruitmentRequest">
              </Pagination>
            </div>
            <defaultTableNoResult v-else />
            <Loading v-if="isLoading" />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      dialog: false,
      isLoading: false,
      id: "",
      listRecruitment: [],
      show_copy: false,
      message: ""
    };
  },
  methods: {
    detail(id) {
      this.$router
        .push({
          name: "hr.selected.applicant.interview.detail",
          params: {
            id: id,
          },
        })
        .catch(() => { });
    },
    fetchRequesterSelected() {
      this.isLoading = true;
      this.$axios
        .get(`/employee/hr/position/recruitment`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.listRecruitment = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listRecruitment.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchRequesterSelected();
  },
};
</script>

<style lang="scss" scoped>
.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
}
</style>
